/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {graphql, Link, useStaticQuery} from "gatsby"

import Header from "./header"
import Footer from "./footer";
import "./layout.css"

const Layout = ({children}) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    return (
        <>
            <Header siteTitle={data.site.siteMetadata.title}/>
            <div
                style={{
                    margin: `0 auto`,
                    padding: `0px 0 3.45rem`,
                    paddingTop: 0,
                }}
            >
                <main>{children}</main>

                <footer className="footer">
                    <div className="container is-fluid columns">
                        <div className="column">
                            © {new Date().getFullYear()} Marina Apartments
                        </div>
                    </div>
                    <hr/>
                </footer>
            </div>
            <Footer/>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
