import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import LogoIcon from "../images/stay-here-square.svg"

const Header = ({siteTitle}) => (
    <div className="navbar" style={{ height: `81px` }}>
        <div className="navbar-brand">
            <div className="navbar-item">
                <Link to="/" style={{textDecoration: `none`}}>
                    <img src={LogoIcon} alt={siteTitle} style={{width: `50px`, margin: 0, display: 'none'}}/>
                    <div className={'title is-3'} style={{color: `#7abc31`}}>Marina Apartments</div>
                </Link>
            </div>
        </div>
        <div className="navbar-menu">
            <div className="navbar-end" style={{display: 'none'}}>
                <a href="/" className="navbar-item">Description</a>
            </div>
        </div>
    </div>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
