//import {Link} from "gatsby"
import React from "react"
import StayHereLogo from "../images/logo-stay-here-queenstown.svg"

const Footer = () => (
    <div style={{
            paddingBottom: `env(safe-area-inset-bottom)`,
            height: `calc(80px + env(safe-area-inset-bottom))`,
            position: `fixed`,
            bottom: `0px`,
            right: `0px`,
            left: `0px`,
            zIndex: `9`,
            backgroundColor: `white`,
            borderTop: `1px solid rgb(235, 235, 235)`
        }}>
        <div className="columns is-vcentered is-marginless" style={{ height: `100%` }}>
            <div className="column is-narrow" style={{ height: `100%` }}>
                <img src={StayHereLogo} alt="Logo" style={{ height: `100%`, display: 'none'}} />
            </div>
            <div className="column">
                <h2>Marina Apartments</h2>
                <p>Queenstown, New Zealand</p>
            </div>
            <div className="column is-narrow" style={{display: 'none'}}>
                From $5 a night
            </div>
            <div className="column is-narrow">
                <button className="button is-primary is-medium is-fullwidth" style={{ paddingLeft: `4rem`, paddingRight: `4rem`, display: 'none' }}>Book</button>
            </div>
        </div>
    </div>
)

export default Footer
